<template>
  <div class="footer">

    <div style="font-size:14px;">
      <span style="font-weight:bold;">主营项目</span>
      <a style="margin-left:5px;" href="https://zqwlkj.net/">教务管理系统</a>
      <a style="margin-left:5px;" href="https://zqwlkj.net/">消课系统消课软件</a>
      <a style="margin-left:5px;" href="https://zqwlkj.net/">排课系统排课软件</a>
      <a style="margin-left:5px;" href="https://zqwlkj.net/">体培教育</a>
      <a style="margin-left:5px;" href="https://zqwlkj.net/">青少年体适能 </a>
    </div>

    <div class="copyright">
      Copyright © 2023 常州果然健康管理有限公司
      <a style="color: #fa541c;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2023052840号</a>

      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32040202000858" style="color: #fa541c;margin-left:5px;display:inline-block;text-decoration:none;">
        <img src="@/assets/beian.png" style="float:left;" />
        苏公网安备32040202000858号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data () {
    return {}
  }
}
</script>

<style  >
.footer {
  padding: 0 16px;
  margin: 0 0 10px;
  text-align: center;

  .links {
    margin-bottom: 8px;

    a {
      color: rgba(0, 0, 0, 0.45);

      &:hover {
        color: rgba(0, 0, 0, 0.65);
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>
